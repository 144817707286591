
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "addition-rented-building-step-1",
  components: {
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
  },
  props: {
    alternativeAccommodationValueRate: Number,
    alternativeAccommodationValueRatePublicId: String,
    policyId: String,
    expiryDate: String,
    packageId: String,
    premiumCalculationRoute: String,
  },
  data() {
    return {
      currentDate: variables.currentDate(),
      startDate: "",
      buildingTypes: BuildingTypes,
      buildingType: "",
      alternativeAccommodation: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      streetAddress: "",
      zipCode: "",
      buildingDescription: "",
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premiumByStartDate() {
      const additionStartDate = this.startDate;

      if (additionStartDate) {
        // Handle non-array fields like the ones you defined (if any)
        const nonArrayFields = [
          "buildingReinstatementValue",
          "lossOfRentValue",
          "houseHoldGoodsAndPersonalEffectsValue",
          "cashValue",
          "thirdPartyLiabilityLimit",
          "benefitLimit",
        ];

        nonArrayFields.forEach((inputField: string) => {
          const element = document.getElementById(
            inputField
          ) as HTMLInputElement;

          if (element) {
            const event = {
              target: element,
              value: element.value,
              type: "input",
            };

            // Call premium function for non-array fields
            this.premium(event, inputField);
          }
        });

        // List of all array-based fields
        const classNames = [
          "phoneDetails-field",
          "laptopOrTabletDetails-field",
          "musicalInstrumentDetails-field",
          "generators-field",
          "jewelryDetails-field",
          "inverterDetails-field",
          "cameraDetails-field",
        ];

        // Loop through class names and handle each field
        classNames.forEach((className: string) => {
          // Get all elements with the current class name
          const elements = document.querySelectorAll(`.${className}`);
          if (elements.length > 0) {
            // Loop through the found elements
            elements.forEach((element, index) => {
              const fieldValue = (element as HTMLInputElement).value;

              if (fieldValue) {
                const event = {
                  target: {
                    value: fieldValue,
                    type: "input",
                  },
                  value: fieldValue,
                };

                // Call the premium function with the appropriate field name and index
                this.premium(event, `${className}[${index}]`);
              }
            });
          }
        });
      }
    },
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      const additionStartDate = this.startDate;

      // const value = variables.removeComma(event.target.value);

      if (additionStartDate == undefined || additionStartDate?.length == 0) {
        return variables.toastAlert(
          "Please select the start date first before specifying a value",
          "error"
        );
      }

      // Check if value is a number
      if (isNaN(value) == false) {
        if (
          JwtService.getToken() &&
          additionStartDate?.length > 0 &&
          value?.toString()?.length > 5
        ) {
          // Calculate premium from vehicle value
          variables.premiumCalculator(
            event.target.ariaValueNow,
            value,
            event.target.ariaValueText
          );

          const payload = {
            businessClass: "HOM",
            package: this.packageId,
            value: value,
            rate: event.target.accessKey,
            startDate: additionStartDate,
          } as object;

          payload[fieldName] = value;

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(`${this.premiumCalculationRoute}`, payload)
              .then(({ data }) => {
                //Assign data to form fields
                // Calculate premium from vehicle value
                variables.premiumCalculator(
                  1,
                  data.data,
                  event.target.ariaValueText
                );

                // Append total premium value tot total premium field
                this.totalPremium = variables.totalPremiumValue();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }
      } else {
        return;
      }
    },
  },
});

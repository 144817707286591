
import { defineComponent } from "vue";
import Phone from "@/components/reusable/PhoneAddition.vue";
import Laptop from "@/components/reusable/LaptopAddition.vue";
import Jewelry from "@/components/reusable/JewelryAddition.vue";
import Camera from "@/components/reusable/CameraAddition.vue";
import MusicalInstrument from "@/components/reusable/MusicalInstrumentAddition.vue";
import Inverter from "@/components/reusable/InverterAddition.vue";

export default defineComponent({
  name: "house-insurance-contents-addition-component",
  components: { Phone, Laptop, Jewelry, Camera, MusicalInstrument, Inverter },
  props: {
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    policyId: String,
    phoneRatePublicId: String,
    laptopRatePublicId: String,
    cameraRatePublicId: String,
    inverterRatePublicId: String,
    musicalInstrumentRatePublicId: String,
    jewelryRatePublicId: String,
    grandPremium: Number,
    premiumCalculationRoute: String,
    packageId: String,
  },
});

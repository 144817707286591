
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Contents from "@/components/reusable/ContentsAddition.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import GeneratorAddition from "@/components/reusable/GeneratorAddition.vue";

export default defineComponent({
  name: "own-building-addition-step-2",
  components: {
    Field,
    ErrorMessage,
    ContentsBuildingModal,
    ScheduleInfoModal,
    Contents,
    GeneratorAddition,
  },
  props: {
    personalEffectsRatePublicId: String,
    thirdPartLiabilityValueRatePublicId: String,
    cashValueRatePublicId: String,
    personalEffectsRate: Number,
    cashValueRate: Number,
    thirdPartLiabilityValueRate: Number,
    phoneRate: Number,
    laptopRate: Number,
    cameraRate: Number,
    inverterRate: Number,
    musicalInstrumentRate: Number,
    jewelryRate: Number,
    phoneRatePublicId: String,
    laptopRatePublicId: String,
    cameraRatePublicId: String,
    inverterRatePublicId: String,
    musicalInstrumentRatePublicId: String,
    jewelryRatePublicId: String,
    policyId: String,
    premiumCalculationRoute: String,
    packageId: String,
    generatorRate: Number,
    generatorRatePublicId: String,
  },
  data() {
    return {
      schedule: "",
      displayContent: false,
      totalPremium: 0.0 as number,
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      policySummary: {
        phoneDetails: {},
        laptopOrTabletDetails: {},
        jewelryDetails: {},
        cameraDetails: {},
        musicalInstrumentDetails: {},
        inverterDetails: {},
      },
    };
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      let value: any = variables.removeComma(newValue);
      const additionStartDate = document.getElementById(
        "startDate"
      ) as HTMLInputElement;

      // Check if value is a number
      if (
        isNaN(value) == false &&
        additionStartDate.value?.toString()?.length > 0
      ) {
        const payload = {
          businessClass: "HOM",
          package: this.packageId,
          value: value,
          rate: event.target.accessKey,
          startDate: additionStartDate.value,
        } as object;

        payload[fieldName] = value;
        value = String(value);

        if (JwtService.getToken() && value?.length > 3) {
          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    displayContents() {
      this.displayContent = this.displayContent == true ? false : true;

      if (this.displayContent == false) {
        setTimeout(() => {
          // Update total premium
          this.totalPremium = variables.totalPremiumValue();
        }, 200);
      }
    },
    scheduleSelected(event) {
      this.schedule = event.target.files[0];
    },
  },
});
